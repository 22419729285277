@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Solway:wght@300;400;500;700;800&display=swap');
@import "~bootstrap/scss/bootstrap";

:root {
  --bs-primary: #f0bdd5;
  --bs-primary-rgb: 240, 189, 213;
  --bs-primary-dark: #e793bb;
  --bs-primary-dark-rgb: 231, 147, 187;
  --bs-primary-dark2: #e27fad;
  --bs-primary-dark2-rgb: 226, 127, 173;
  --bs-primary-light: #f9e7ef;
  --bs-primary-light-rgb: 249, 231, 239;
  --bs-body-bg: #f0bdd5;
  --bs-body-color: #ffffff;
  --bs-link-color: #e793bb;
  --bs-link-hover-color: #e27fad;
}

html {
  margin: 0;
  background-color: var(--bs-body-bg);
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Solway', serif;
  font-size: 16px;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  text-align: center;
}

@media screen and (max-width: 640px) {
  body {
    font-size: 15px;
  }

  .lead {
    font-size: 1rem !important;
  }
}

.group-choice {
  padding-left: 100px;
}

.group-choice input[type=number] {
  width: calc(100% - 100px);
}


@media screen and (max-width: 991px) {
  .group-choice {
    padding-left: 0;
    width: 65vw;
    margin: auto;
  }
}

@media screen and (max-width: 875px) {
  .group-choice {
    width: 70vw;
  }
}

@media screen and (max-width: 811px) {
  .group-choice {
    width: 80vw;
  }
}

@media screen and (max-width: 711px) {
  .group-choice {
    width: 90vw;
  }
}



section {
  padding-left: 3em;
  padding-right: 3em;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-family: 'Parisienne', cursive;
  font-optical-sizing: auto;
  color: var(--bs-primary-dark);
  font-size: 3em;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  margin-top: .7em;
  margin-bottom: 0;
}

h1 a {
  color: var(--bs-primary-dark);
  text-decoration: none;
}

h1 a:hover {
  color: var(--bs-primary-light);
}

.card {
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-border-color: var(--bs-body-color);
}

.btn-outline-light {
  --bs-btn-hover-color: var(--bs-body-bg);
  --bs-btn-active-color: var(--bs-body-bg);
}

.modal {
  --bs-modal-color: var(--bs-body-color);
  --bs-modal-bg: var(--bs-body-bg);
}

.btn-primary {
  --bs-btn-bg: var(--bs-primary-dark);
  --bs-btn-border-color: var(--bs-primary-dark);
  --bs-btn-hover-bg: var(--bs-primary-dark2);
  --bs-btn-hover-border-color: var(--bs-primary-dark2);
  --bs-btn-focus-shadow-rgb: var(--bs-primary-dark2-rgb);
  --bs-btn-active-bg: var(--bs-primary-dark2);
  --bs-btn-active-border-color: var(--bs-primary-dark2);
}

.btn-outline-primary {
  --bs-btn-color: var(--bs-primary-dark2);
  --bs-btn-border-color: var(--bs-primary-dark2);
  --bs-btn-hover-bg: var(--bs-primary-dark2);
  --bs-btn-hover-border-color: var(--bs-primary-dark2);
  --bs-btn-focus-shadow-rgb: var(--bs-primary-dark2-rgb);
  --bs-btn-active-bg: var(--bs-primary-dark2);
  --bs-btn-active-border-color: var(--bs-primary-dark2);
  --bs-btn-disabled-color: var(--bs-primary-dark);
  --bs-btn-disabled-border-color: var(--bs-primary-dark);
}




.form-control:focus,
.form-select:focus,
.form-check-input:focus {
  border-color: var(--bs-primary-dark);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-dark-rgb), 0.5);
}

.form-check-input:checked {
  background-color: var(--bs-primary-dark);
  border-color: var(--bs-primary-dark);
}

.text-primary {
  color: var(--bs-primary-dark) !important;
}

.toast-container {
  position: fixed !important;
}

.toast-body {
  padding-left: 1.5em;
  padding-right: 1.5em;
  color: var(--bs-dark);
  text-align: left;
}

.toast-header {
  background-color: var(--bs-primary);
  color: white;
}

.text-primary-dark {
  color: var(--bs-primary-dark);
}

.text-primary-dark2 {
  color: var(--bs-primary-dark2);
}

.text-primary-light {
  color: var(--bs-primary-light);
}

.signature {
  font-family: 'Parisienne', cursive;
  font-optical-sizing: auto;
  font-size: 2em;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.footer {
  font-family: 'Parisienne', cursive;
  font-optical-sizing: auto;
  // color: var(--bs-primary-dark);
  font-size: 2em;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.btn-close {
  color: #FFF;
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

p {
  font-weight: 300;
}

td {
  vertical-align: middle;
}

.invalid-feedback {
  text-align: left;
}

.fw-500 {
  font-weight: 500;
}

.card-subtitle {
  line-height: 22px;
}

address {
  font-style: italic;
  line-height: 1.2;
}


.modal-content>form {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

#root>p {
  padding-left: 2em;
  padding-right: 2em;
}